import React, { useState, useEffect, useRef } from 'react';
import './certificate.scss';
import logo from '../../img/EarthID Logo - Primary - BLACK.png'
import favicon from '../../img/EarthID FAVICON - PRIMARY.png'
import check from '../../img/check.png'
import ssiApi from '../../ssiApi.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useParams } from "react-router-dom";
import QRCode from 'qrcode.react';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, pdf } from '@react-pdf/renderer';
import CertificatePDF from './downloadCert';
import { saveAs } from 'file-saver'; // Import the file-saver library
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import CertificateIcon from '../../img/certIcon.png'
import BadgeIcon from '../../img/badgeIcon.png'
import BadgeImg from '../../img/badgeImg.jpeg'
import YellowBadge from '../../img/rosette.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocation, faPhone, faEnvelope, faEllipsisVertical, faIdBadge, faFilePdf, faImage, faShare, faQuestionCircle, faArrowDown, faCaretDown, faPen, faUser, faMedal, faArrowUpRightFromSquare, faCheckCircle, faBitcoinSign, faEye } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagramSquare, faLinkedin, faYoutubeSquare, faTwitterSquare, faWhatsapp, faFacebookSquare, faWhatsappSquare, faLinkedinIn, faTwitter, faEmber, faBitcoin } from '@fortawesome/free-brands-svg-icons'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';

import { JsonToTable } from "react-json-to-table";
import axios from 'axios';
const moment = require('moment');


const ssiBaseUrl = "https://ssi-test.myearth.id";
const authorizationKey = "01a41742-aa8e-4dd6-8c71-d577ac7d463c";

const privateKey = "6mf4ZKu2ts0V4Dr0sS+YbXYveZOyDezzTJUqa5Ls29BHN4jBTY5we/WW0CgmuNGb3PBvdqqJPhDF2YCMnF4faw==";
const publicKey = "RzeIwU2OcHv1ltAoJrjRm9zwb3aqiT4QxdmAjJxeH2s=";
const issuerDID = "did:earthid:testnet:2utZGEuzWe8ds5Jaxd7KdMD7bwKMm8QiRKnS2t4Q8Dve;earthid:testnet:fid=0.0.15057499";
const userDID = "did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499";




const Certificate = () => {
    const [responseData, setResponseData] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isVerificationLoading, setIsVerificationLoading] = useState([true, true, true, true, true]);
    const [verificationData, setVerificationData] = useState([]);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    const [showCertificate, setShowCertificate] = useState(true);
    const [vcs, setVcs] = useState([]);
    const [docs, setDocs] = useState([]);
    const [onBoardingvcs, setOnBoardingVcs] = useState([]);
    //const [showJson, setShowJson] = useState(true);
    const { userName, vcID } = useParams();
    const qrCodeUrl = `https://audit.myearth.id/${userName}/vcs/audit/${vcID}`;

    const pdfContainerRef = useRef();


    const certificationName = 'Audit Certificate';
    const certUrl = `https://audit.myearth.id/${userName}/vcs/audit/${vcID}`;



    const MySwal = withReactContent(Swal);


    useEffect(() => {


        const fetchData = async () => {
            try {
                const prefix = 'AuditCertificate'; // Replace with your desired prefix
                const userNamee = `${prefix}${userName}`;
                console.log('Username', userNamee)
                //const cidsResponse = await fetch(`https://auditdb.myearth.id/api/vcnew/company/${userNamee}/CertificateVc`);
                    // Make a POST request to fetch the VC using vcID
            const cidsResponse = await fetch('https://auditdb.myearth.id/api/vcnew/show', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    vcID: vcID, // Pass the vcID in the body
                }),
            });
                const cidJson = await cidsResponse.json();
                console.log('CidsResponse', cidJson.response);

                if (cidJson && cidJson.response && cidJson.response.length > 0) {
                    // const response = await fetch('https://auditdb.myearth.id/api/vc/fetchVCMain', {
                    //     method: 'POST',
                    //     headers: {
                    //         'Accept': 'application/vnd.ipld.car',
                    //         'Content-Type': 'application/json',
                    //     },
                    //     body: JSON.stringify({
                    //         cids: [
                    //             cidJson.cids[cidJson.cids.length - 1]
                    //         ],
                    //     }),
                    // });
                    setResponseData(cidJson.response);
                    // if (response.ok) {
                    //     const json = await response.json();
                    //     console.log(json);
                    //     setResponseData(json);
                    // } else {
                    //     console.log('Request failed with status code:', response.status);
                    // }
                } else {
                    console.log('Certificate not generated');
                    //Swal.fire('Certificate Not Generated');
                }
            } catch (error) {
                console.log('Error:', error);
            }
            finally {
                setIsLoading(false);
            }
        };

        const fetchVcs = async () => {
            try {
                // Fetch the document CIDs for the given user
                //const userName = 'EarthID';
                const fetchDocsResponse = await fetch(`https://auditdb.myearth.id/api/vcnew/company/${userName}/DocumentVc`);
                const fetchDocsData = await fetchDocsResponse.json();
                console.log(fetchDocsData);
    
                if (!Array.isArray(fetchDocsData.response)) {
                    throw new Error('Invalid response data format');
                }
    
                // Prepare the request body with the CIDs
                const requestBody = {
                    response: fetchDocsData.response,
                };
                console.log(requestBody);
    
                // Fetch the files based on the CIDs
                // const fetchFilesResponse = await fetch('https://auditdb.myearth.id/api/vc/fetchVC', {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                //     body: JSON.stringify(requestBody),
                // });
    
                // if (!fetchFilesResponse.ok) {
                //     // Display Swal alert instead of throwing an error
                //     MySwal.fire({
                //         icon: 'error',
                //         title: 'Error',
                //         text: 'Error fetching files. Please try again.',
                //     });
                // }
    
                // //const fetchFilesText = await fetchFilesResponse.json();
                // //const documentUrls = fetchFilesText.split('\n').map((url) => url.trim());
                // const vcsData = await fetchFilesResponse.json();
                // console.log('DocVCS',vcsData);
    
                // Separate the VCs into arrays based on schemaName
                //const idVcs = [];
                const onBoardingVcs = [];
    
                requestBody.response.forEach((vc) => {
                    
                     if (vc.version == 'AuditDemoSchema:1') {
                        onBoardingVcs.push(vc);
                    }
                    // Add more conditions for additional categories if needed
                });
    
                // Set the arrays of VCs to the state variables
                
                setOnBoardingVcs(onBoardingVcs);
    
                // Set the array of VCs to the state variable
                setVcs(requestBody.response);
    
                // Set the array of document URLs to the state variable
                //setPdfs((prevPdfs) => [...prevPdfs, [fetchFilesText]]);
                // Return the array of document URLs
                //return fetchFilesText;
    
            } catch (error) {
                console.error('Error fetching documents:', error);
                // Display Swal alert instead of throwing an error
                MySwal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error fetching user list. Please try again.',
                });
            }
        };

        const fetchDocs = async () => {
            try {
                // Fetch the document CIDs for the given user
                //const userName = 'EarthID';
                const fetchDocsResponse = await fetch(`https://auditdb.myearth.id/api/docnew/company/${userName}`);
                const fetchDocsData = await fetchDocsResponse.json();
                console.log(fetchDocsData);
    
                if (!Array.isArray(fetchDocsData.response)) {
                    throw new Error('Invalid response data format');
                }
    
                // Prepare the request body with the CIDs
                const requestBody = {
                    response: fetchDocsData.response,
                };
                console.log(requestBody);
    
                // Fetch the files based on the CIDs
                // const fetchFilesResponse = await fetch('https://auditdb.myearth.id/api/vc/fetchVC', {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                //     body: JSON.stringify(requestBody),
                // });
    
                // if (!fetchFilesResponse.ok) {
                //     // Display Swal alert instead of throwing an error
                //     MySwal.fire({
                //         icon: 'error',
                //         title: 'Error',
                //         text: 'Error fetching files. Please try again.',
                //     });
                // }
    
                // //const fetchFilesText = await fetchFilesResponse.json();
                // //const documentUrls = fetchFilesText.split('\n').map((url) => url.trim());
                // const vcsData = await fetchFilesResponse.json();
                // console.log('DocVCS',vcsData);
    
                // Separate the VCs into arrays based on schemaName
                //const idVcs = [];
            const onBoardingDocs = [];
    
                requestBody.response.forEach((vc) => {
                    
                     if (vc.version == 'AuditDemoSchema:1') {
                        onBoardingDocs.push(vc);
                    }
                    // Add more conditions for additional categories if needed
                });
    
                // Set the arrays of VCs to the state variables
                
                //setOnBoardingVcs(onBoardingVcs);
    
                // Set the array of VCs to the state variable
                setDocs(requestBody.response);
    
                // Set the array of document URLs to the state variable
                //setPdfs((prevPdfs) => [...prevPdfs, [fetchFilesText]]);
                // Return the array of document URLs
                //return fetchFilesText;
    
            } catch (error) {
                console.error('Error fetching documents:', error);
                // Display Swal alert instead of throwing an error
                MySwal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error fetching user list. Please try again.',
                });
            }
        };

        fetchData();
        fetchVcs();
        fetchDocs();
    }, []);





    useEffect(() => {
        if (isButtonClicked && responseData) {
            //   setIsLoading(true);
            setIsVerificationLoading([true, true, true, true, true]);
            setVerificationData([]);

            setTimeout(() => {
                //setIsLoading(false);
                setIsVerificationLoading([true, true, true, true, true]);
                setVerificationData([
                    moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                    moment(responseData[0].vc.expirationDate).format('MMMM Do YYYY'),
                    "EarthID",
                    userName
                ]);

                setTimeout(() => {
                    setIsVerificationLoading([false, true, true, true, true]);
                    setVerificationData([
                        moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                        moment(responseData[0].vc.expirationDate).format('MMMM Do YYYY'),
                        "EarthID",
                        userName
                    ]);

                    setTimeout(() => {
                        setIsVerificationLoading([false, false, true, true, true]);
                        setVerificationData([
                            moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                            moment(responseData[0].vc.expirationDate).format('MMMM Do YYYY'),
                            "EarthID",
                            userName
                        ]);

                        setTimeout(() => {
                            setIsVerificationLoading([false, false, false, true, true]);
                            setVerificationData([
                                moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                                moment(responseData[0].vc.expirationDate).format('MMMM Do YYYY'),
                                "EarthID",
                                userName
                            ]);

                            setTimeout(() => {
                                setIsVerificationLoading([false, false, false, false, true]);
                                setVerificationData([
                                    moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                                    moment(responseData[0].vc.expirationDate).format('MMMM Do YYYY'),
                                    "EarthID",
                                    userName
                                ]);

                                setTimeout(() => {
                                    setIsVerificationLoading([false, false, false, false, false]);
                                    setVerificationData([
                                        moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                                        moment(responseData[0].vc.expirationDate).format('MMMM Do YYYY'),
                                        "EarthID",
                                        userName
                                    ]);
                                }, 1000);
                            }, 1000);
                        }, 1000);
                    }, 1000);
                }, 1000);
            }, 12000);
        }
    }, [isButtonClicked, responseData]);

    const getYearandMonth = (responseData) => {
        if (responseData && responseData.length > 0) {
            const issueYear = moment(responseData[0].vc.issuanceDate).format('YYYY')
            console.log(issueYear)
            const issueMonth = moment(responseData[0].vc.issuanceDate).format('MM')
            console.log(issueMonth)

            const expiryYear = moment(responseData[0].vc.expirationDate).format('YYYY')
            console.log(expiryYear)
            const expiryMonth = moment(responseData[0].vc.expirationDate).format('MM')
            console.log(expiryMonth)
            // Return an object containing the formatted values
            return {
                issueYear,
                issueMonth,
                expiryYear,
                expiryMonth,
            };
        }
        return null;
    }

    const dateData = getYearandMonth(responseData)
    console.log(dateData)

    const generateLinkedInURL = (certificationName, dateData, certUrl) => {
        if (!dateData) {
            // Handle the case when dateData is null (responseData is not available or empty)
            console.error("dateData is null. Cannot generate LinkedIn URL.");
            return null;
        }

        const linkedInURL = `https://www.linkedin.com/profile/add?startTask=${encodeURIComponent(
            certificationName
        )}&name=Audit%20Certificate&issuingOrganization=EarthID&issueYear=${encodeURIComponent(dateData.issueYear)}&issueMonth=${encodeURIComponent(
            dateData.issueMonth
        )}&expirationYear=${encodeURIComponent(dateData.expiryYear)}&expirationMonth=${encodeURIComponent(
            dateData.expiryMonth
        )}&certUrl=${encodeURIComponent(certUrl)}`;

        return linkedInURL;
    };

    const linkedInURL = generateLinkedInURL(
        certificationName,
        dateData,
        certUrl
    );



    const extractLastPart = (responseData) => {
        if (responseData && responseData.length > 0 && responseData[0].vc.id) {
            const id = responseData[0].vc.id;
            const parts = id.split('-');
            const lastPart = parts[parts.length - 1];
            return lastPart;
        }
        return null;
    }

    const lastPart = extractLastPart(responseData);

    //Verify Api

    const createUserSignature = async (documentMetadata) => {
        try {
            const userDid = await createUserDid();
            const payload = {
                payload: {
                    credentialSubject: {
                        id: userDid,
                        pdfMetaData: documentMetadata,
                    },
                },
            };

            const config = {
                method: 'post',
                url: `${ssiBaseUrl}/api/user/sign?issuerDID=${issuerDID}`,
                headers: {
                    'X-API-KEY': authorizationKey,
                    privateKey,
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(payload),
            };

            const response = await axios.request(config);
            console.log(response.data)
            return response.data.Signature;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };


    const verifyVC = async () => {
        try {

            //const signature = await createUserSignature();

            const data = {
                "credentials": {
                    ...responseData[0].vc
                }
            }
            const config = {
                method: 'post',
                url: ssiBaseUrl + "/api/verifier/verify?zpkValidation=false",
                headers: {
                    'X-API-KEY': authorizationKey,
                    'publicKey': publicKey,
                    //'userSignature': signature, // Call the createUserSignature function
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };

            const response = await axios.request(config);
            console.log(JSON.stringify(response.data));

            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    const verifyVCList = async (vc) => {
        try {

            //const signature = await createUserSignature();

            const data = {
                "credentials": vc
                
            }
            const config = {
                method: 'post',
                url: ssiBaseUrl + "/api/verifier/verify?zpkValidation=false",
                headers: {
                    'X-API-KEY': authorizationKey,
                    'publicKey': publicKey,
                    //'userSignature': signature, // Call the createUserSignature function
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };

            const response = await axios.request(config);
            console.log(JSON.stringify(response.data));

            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }


    const createUserDid = async () => {
        // Implement the logic to create a user DID (if required)
        // For now, returning a placeholder value
        return 'did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499';
    };

    //Verify Api

    const handlePopupOpen = () => {
        setIsPopupOpen(true);
        setIsVerifying(true);
        setIsButtonClicked(true);

        // Call verifyVC() here
        verifyVC().then((verificationResult) => {
            // Process the verification result and update state variables accordingly
            setIsVerifying(false);
            // Update other state variables based on verification result
            // Example:
            // setIsVerificationLoading([...]);
            // setVerificationData([...]);
        }).catch((error) => {
            // Handle error
            setIsVerifying(false);
            // Display error message or take appropriate action
        });
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
    };

    // function toggleView() {
    //     setShowJson(!showJson);
    //   }

    const handleViewCertificate = () => {
        const MySwal = withReactContent(Swal);

        MySwal.fire({
            title: 'Digital Certificate(VC)',
            html: <pre>{JSON.stringify(responseData[0], null, 2)}</pre>,
            confirmButtonText: 'Verify',
            showCancelButton: true,
            cancelButtonText: 'Close',
            customClass: {
                content: 'certificate-swal',
                confirmButton: 'swal-button',
                cancelButton: 'swal-button swal-button--cancel',
            },
            width: '1000px',
            didOpen: () => {
                const verifyButton = MySwal.getConfirmButton();
                verifyButton.addEventListener('click', () => {
                    const loadingSwal = MySwal.mixin({
                        title: 'Loading',
                        text: 'Performing verification...',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showConfirmButton: false,
                        onOpen: () => {
                            loadingSwal.showLoading();
                            verifyVC()
                                .then(() => {
                                    // Display success message upon successful verification
                                    loadingSwal.close();
                                    MySwal.fire({
                                        title: 'Verification Successful',
                                        text: '',
                                        icon: 'success',
                                        showConfirmButton: true,
                                        confirmButtonText: 'OK',
                                        customClass: {
                                            confirmButton: 'swal-button',
                                        },
                                    });
                                })
                                .catch((error) => {
                                    // Handle error case and display error message
                                    loadingSwal.close();
                                    MySwal.fire({
                                        title: 'Verification Error',
                                        text: 'An error occurred during verification.',
                                        icon: 'error',
                                        confirmButtonText: 'OK',
                                        allowOutsideClick: true,
                                        allowEscapeKey: true,
                                        showCancelButton: false,
                                        customClass: {
                                            confirmButton: 'swal-button',
                                        },
                                    });
                                });
                        },
                    });

                    loadingSwal.fire();
                });
            },
        });
    };


    const handleViewAuditTrail = () => {
        const MySwal = withReactContent(Swal);
    
        const renderVCList = () => {
            return docs.map((doc, index) => {
              const vc = vcs[index]; // Get the corresponding document from the 'docs' array
          
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{doc ? `Uploaded Document ${ doc.originalFileName}` : 'N/A'}</td>
                  <td>
                    <FontAwesomeIcon onClick={() => handleViewButtonClicked(vc)} className="eyeIcon" icon={faEye} />
                  </td>
                </tr>
              );
            });
          };

        const rendermainVCList = () => {
            return responseData.map((vc, index) => {
                // Calculate the offset based on the length of vcs array
                const offset = docs.length;
                const newIndex = index + offset + 1; // Adding 1 to start index from 1
        
                return (
                    <tr key={index}>
                        <td>{newIndex}</td>
                        <td>Audit Certificate Issued #{index + 1}</td>
                        
                        <td>
                <FontAwesomeIcon onClick={() => handleViewButtonClicked(vc)} className="eyeIcon" icon={faEye} />
              </td>
                    </tr>
                );
            });
        };
        
   
    
        const handleViewButtonClicked = (vc) => {
            MySwal.close();
    
            // Load the specific VC response for viewing
            const vcData = JSON.stringify(vc, null, 2);
    
            MySwal.fire({
                title: 'Digital Certificate(VC)',
                html: (
                    <div>
                        <pre>{vcData}</pre>
                    </div>
                ),
                confirmButtonText: 'Verify',
                showCancelButton: true, // Show the Cancel button
                cancelButtonText: 'Close', // Text for the Cancel button
                customClass: {
                    content: 'certificate-swal',
                    confirmButton: 'swal-button',
                    cancelButton: 'swal-button swal-button--cancel',
                },
                width: '1000px',
            }).then((result) => {
                if (result.isConfirmed) {
                    handleVerifyButtonClicked(vc);
                }
            });
        };
    
        const handleVerifyButtonClicked = (vc) => {
            const loadingSwal = MySwal.mixin({
                title: 'Performing verification...',
                showConfirmButton: false,
                onBeforeOpen: () => {
                    Swal.showLoading(); // Show the built-in loading spinner
                },
                allowOutsideClick: () => !Swal.isLoading(), // Prevent clicking outside while loading
            });
        
            loadingSwal.fire();
        
            // Call your verifyVCList function and wait for the response
            verifyVC(vc)
                .then((verificationSuccessful) => {
                    loadingSwal.close();
        
                    if (verificationSuccessful) {
                        MySwal.fire({
                            title: 'Verification Successful',
                            text: '',
                            icon: 'success',
                            showConfirmButton: true,
                            confirmButtonText: 'OK',
                            customClass: {
                                confirmButton: 'swal-button',
                            },
                        });
                    } else {
                        MySwal.fire({
                            title: 'Verification Failed',
                            text: 'The verification process failed.',
                            icon: 'error',
                            confirmButtonText: 'OK',
                            customClass: {
                                confirmButton: 'swal-button',
                            },
                        });
                    }
                })
                .catch((error) => {
                    loadingSwal.close();
                    console.error('Verification error:', error);
                    MySwal.fire({
                        title: 'Verification Error',
                        text: 'An error occurred during verification.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'swal-button',
                        },
                    });
                });
        };
        
    


        MySwal.fire({
            title: 'Steps performed while generating certificate:',
            html: (
                <div className="user-tablee">
<table className="user-table">
                    <thead>
                        <tr>
                            <th>Sr No.</th>
                            <th>VC Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>{renderVCList()}</tbody>
                    <tbody>{rendermainVCList()}</tbody>
                </table>
                </div>
                
            ),
            showCancelButton: true,
            cancelButtonText: 'Close',
            customClass: {
                content: 'certificate-swal',
                confirmButton: 'swal-button',
                cancelButton: 'swal-button swal-button--cancel',
            },
            width: '1000px',
        });
    };

    const handleToggleCertificate = () => {
        setShowCertificate(true);
    };

    const handleToggleBadge = () => {
        setShowCertificate(false);
    };

    // Function to handle the PDF download
    const handleDownload = () => {
        const pdfContainer = pdfContainerRef.current;

        // Create a new jsPDF instance with the A4 landscape dimensions
        const pdf = new jsPDF('l', 'pt', [500, 740]);

        // Use html2canvas to render the content of the container as an image
        html2canvas(pdfContainer, { scale: 3, useCORS: true }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            pdf.addImage(imgData, 'JPEG', 0, 0, 740, 500);
            pdf.save(`audit-certificate(${userName}).pdf`);
            // Open the PDF in a new window
            //   const pdfWindow = window.open('', '_blank');
            //   pdfWindow.document.write('<iframe width="100%" height="100%" src="' + pdf.output('datauristring') + '"></iframe>');
        });
    };

    const handleShareLinkedIn = () => {
        // Open the LinkedIn URL in a new tab
        window.open(linkedInURL, '_blank');
    };
    // const handleShareLinkedIn = () => {
    //     // Wait until the LinkedIn SDK is fully loaded
    //     window.onload = function () {
    //       // Access the IN object and use it here
    //       IN.UI.Share().params({
    //         url: 'YOUR_LINKEDIN_SHARE_URL',
    //         mini: true,
    //       }).place();
    //     };
    //   };
    //   const handleViewCertificate = () => {
    //     const MySwal = withReactContent(Swal);

    //     MySwal.fire({
    //       title: 'Digital Certificate(VC)',
    //       html: <pre>{JSON.stringify(responseData[0], null, 2)}</pre>,
    //       confirmButtonText: 'Close',
    //       customClass: {
    //         content: 'certificate-swal',
    //       },
    //     });
    //   };


    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            {responseData && responseData.length > 0 ? (
                <div className="page-container">
                    <nav className="navbar-cert">
                        <div className="logo">
                            <img src={logo}></img>
                        </div>
                        <ul className="nav-menu">
                            <li className="nav-item"><a href={`/${userName}/vcs/audit/${vcID}`}>Home</a></li>
                        </ul>
                    </nav>
                    <div className="certificate-container">
                        <section className={showCertificate ? 'certBackk' : 'badgeBackk'}>
                            <div className='row'>
                                {/* <div className="icon-wrapper">
                                    <img
                                        src={CertificateIcon}
                                        alt="Certificate Icon"
                                        className={showCertificate ? 'active-icon' : 'inactive-icon'}
                                        onClick={handleToggleCertificate}
                                    />
                                    <img
                                        src={BadgeImg}
                                        alt="Badge Icon"
                                        //style={{ height: '150px', width: '130px' }}
                                        className={!showCertificate ? 'active-icon' : 'inactive-icon'}
                                        onClick={handleToggleBadge}
                                    />
                                </div> */}
                                {showCertificate ? (
                                    <div ref={pdfContainerRef} className="certificated">
                                        <div className="bodbox">

                                            <div className="header">
                                                <div className="favico">
                                                    <img src={favicon}></img>
                                                </div>
                                                <div className="title">Audit Certificate</div>
                                            </div>
                                            <div className="title1">Year 2023-2024</div>
                                            <div className="title1">This certificate verifies that</div>

                                            <div className="body">
                                                <div className="name">{userName}</div>
                                                <div className="title1">has succesfully gone through the audit and <br></br>has completed all requirements for the necessary industry standards.<br></br>This certificate has been <br></br>
                                                issued on: {moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY, h:mm:ss A')}.
                                                </div>
                                            </div>
                                            <div>
                                                {/* Add the QRCode component */}
                                                <div className="qrcode-container">
                                                    <QRCode className='qrCode' value={qrCodeUrl} />
                                                </div>
                                                <div className='signature'>EarthID</div>
                                                {/* <div className='signature1'>KPMG</div> */}
                                            </div>


                                        </div>
                                    </div>) : (
                                        <></>
                                    // <div className="badge-container">
                                    //     <img
                                    //         src={BadgeImg}
                                    //         alt="Badge Icon"
                                    //         //style={{ height: '450px', width: '380px' }}
                                    //         className={'badgeImg'}
                                    //         onClick={handleToggleBadge}
                                    //     />
                                    // </div>
                                )}
                            </div>

                        </section>
                    </div>
                    {/* Add the PDF download button */}
                    {/* Add the download button */}

                    <section className='infosec'>
                        <div >
                            <div className='row'>
                                <div className='leftsec col-md-8'>
                                    <a href='https://myearth.id' target='blank'>
                                        <div className='issuerName'>

                                            <FontAwesomeIcon className='issuerfontIcon1' icon={faMedal} />

                                            EarthID

                                            <FontAwesomeIcon className='issuerfontIcon' icon={faArrowUpRightFromSquare} />


                                        </div>
                                    </a>

                                    <div className='row'>
                                        <div className="check col-md-1">
                                            <img src={check}></img>
                                        </div>
                                        <div className='coursename col-md-11'>Customer Audit Certificate</div>
                                    </div>
                                    <div className='allBtns row'>
                                        <button className='pdfbtn' title='Download Pdf' onClick={handleDownload}>
                                            <FontAwesomeIcon className='fontIcon' icon={faFilePdf} />
                                            PDF
                                        </button>
                                        {/* <button className='pdfbtn' title='Feature Currently Disabled'>
                                            <FontAwesomeIcon className='fontIcon' icon={faImage} />
                                            BADGE
                                        </button> */}
                                        <button className='pdfbtn' title='Feature Currently Disabled'>
                                            <FontAwesomeIcon className='fontIcon' icon={faShare} />
                                            EMAIL
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>
                                            <FontAwesomeIcon className='fontIcon' icon={faPen} />
                                            EMBED
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>
                                            <FontAwesomeIcon className='fontIcon' icon={faQuestionCircle} />
                                            HELP
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>

                                            MORE
                                            <FontAwesomeIcon className='fontIcon1' icon={faCaretDown} />
                                        </button>
                                    </div>
                                    <div className='studNameDiv'>
                                        <FontAwesomeIcon className='fontIcon' icon={faUser} />
                                        {userName}
                                    </div>
                                    <a href={`/${userName}/vcs/audit`} title='Feature Currently Disabled' target='_blank'>
                                        <div className='viewCreds'>View All Certificates</div>
                                    </a>


                                    {/* <div className='stuID'>ID: EarthID</div> */}


                                    <hr></hr>
                                    <div className='description'>
                                    We are pleased to announce that {userName} has been awarded a successful audit certificate, recognizing their commitment to excellence and adherence to industry standards. This achievement reflects {userName}'s dedication to maintaining high levels of transparency, accountability, and compliance in their operations. The certificate serves as a testament to {userName}'s
                                        exceptional performance and positions them as a trusted and reliable organization in their field.
                                    </div>
                                    <div className='skillsdiv'>
                                        <p>Key Features</p>
                                        <div className='row'>
                                            <div className='allSkills'>
                                            Completion
                                            </div>
                                            <div className='allSkills'>
                                            Compliance
                                            </div>
                                            <div className='allSkills'>
                                            Accuracy
                                            </div>
                                            <div className='allSkills'>
                                            Verification
                                            </div>

                                        </div>
                                    </div>
                                    <hr></hr>

                                    <div className='row'>
                                        <div className='issueon col-md-6'>
                                            <div>Issued On</div>
                                            <div>{moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY, h:mm:ss A')}</div>
                                        </div>
                                        <div className='expiry col-md-6'>
                                            <div>Expires On</div>
                                            <div>{moment(responseData[0].vc.expirationDate).format('MMMM Do YYYY, h:mm:ss A')}</div>
                                        </div>
                                    </div>

                                </div>
                                <div className='rightsec col-md-4'>
                                    {/* <div className="footer11"> */}
                                        {/* <div className='row'>
                                            <div className='col-md-9'>
                                                <div className='verify'>Share Credential</div>
                                                <div className='verify1'>Show this credential on your social network</div>
                                            </div>
                                            <div className='yellowBadge col-md-3'>
                                                <img src={YellowBadge}></img>

                                            </div>
                                        </div> */}

                                        {/* <div className='row iconns-div'>
                                            <a className='shareIcon' onClick={handleShareLinkedIn}>
                                                <FontAwesomeIcon className='iconns' icon={faLinkedinIn} />
                                            </a>
                                            <FacebookShareButton
                                                url={certUrl}
                                            >
                                                <FontAwesomeIcon className='iconns' icon={faFacebook} />
                                            </FacebookShareButton>
                                            <TwitterShareButton
                                                url={certUrl}
                                            >
                                                <FontAwesomeIcon className='iconns' icon={faTwitter} />
                                            </TwitterShareButton>
                                            <WhatsappShareButton
                                                url={certUrl}
                                            >
                                                <FontAwesomeIcon className='iconns' icon={faWhatsapp} />
                                            </WhatsappShareButton>
                                        </div>
                                        <button className='verify-btn' onClick={handleShareLinkedIn}>
                                            <FontAwesomeIcon className='iconns1' icon={faLinkedin} />  Share on LinkedIn
                                        </button> */}
                                        {/* <a href="https://www.linkedin.com/company/myearthid/" target="_blank"><FontAwesomeIcon icon={faLinkedin}/></a> */}

                                    {/* </div> */}
                                    <div className="footer2">
                                        <div className='verify'>Certificate Verification</div>
                                        <div className='verify1'>
                                            <FontAwesomeIcon className='fontIcon21' icon={faCheckCircle} />
                                            This certificate is from a <span> verified issuer</span>
                                        </div>
                                        <div className='verify1'>
                                        {/* <div className="check">
                                            <img src={check}></img>
                                        </div> */}
                                        <FontAwesomeIcon className='fontIcon21' icon={faCheckCircle} />
                                            Certificate ID: <span>{lastPart}</span>
                                        </div>
                                        <button className="verify-btn" onClick={handlePopupOpen}>
                                            Verify
                                        </button>
                                        <button className='verify-btn' onClick={handleViewCertificate}>View Digital Certificate</button>
                                        <button className='verify-btn' onClick={handleViewAuditTrail}>View Audit Trail</button>
                                    </div>
                                    <div className="footer2">
                                        <div className='verify'>More about the Issuer</div>
                                        <div className='verify1'>EarthID</div>
                                        <button className="verify-btn" onClick={() => window.open('https://myearth.id', '_blank', 'noopener')} rel="noopener">
                                            Visit Issuer Website
                                            <FontAwesomeIcon className='issuerfontIcon' icon={faArrowUpRightFromSquare} />
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </section>

                    {isPopupOpen && (
                        <div className="popup-container">
                            {isVerifying ? (
                                <div>
                                    <div className="spinner"></div>
                                    <div className="spinner-text">Verifying Credentials...</div>
                                </div>
                            ) : (
                                <div className="popup">
                                    <div className="popup-header">
                                        <div className="popup-title">Verification Details</div>
                                        <button className="popup-close" onClick={handlePopupClose}>
                                            X
                                        </button>
                                    </div>
                                    <hr></hr>
                                    <div className="popup-body">
                                        {isVerificationLoading[0] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Verifying Issuer...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>
                                                    {/* <div className="verification-label">Issuer is Valid:</div> */}

                                                    <div className="verification-result1">
                                                        <div>This issuer is verified by EarthID</div>
                                                        <div>The issuer is found to be valid</div>

                                                    </div>
                                                </div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        {/* <div className="success">&#10004;</div> */}

                                                    </div>
                                                    {/* <div className="verification-label">Issuer is Valid:</div> */}

                                                    <div className="verification-result1 row">

                                                        <a href='https://myearth.id' target='blank'>
                                                            <div className='issuerNamepop'>

                                                                <FontAwesomeIcon className='issuerfontIcon1' icon={faMedal} />

                                                                EarthID

                                                                <FontAwesomeIcon className='issuerfontIcon' icon={faArrowUpRightFromSquare} />


                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[1] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Checking Certificate ID...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>
                                                    {/* <div className="verification-label">Issued by:</div> */}

                                                    <div className="verification-result1">
                                                        {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                        <div>The authenticity of this credential can be independently verified.</div>

                                                    </div>
                                                </div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        {/* <div className="success">&#10004;</div> */}

                                                    </div>
                                                    {/* <div className="verification-label">Issued by:</div> */}

                                                    <div className="verification-result1">
                                                        {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                        <div className="footer22">
                                                            <div className='verify1'>
                                                            <FontAwesomeIcon className='fontIcon22' icon={faCheckCircle} />
                                                                Certificate ID: <span>{lastPart}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[2] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Verifying Owner...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>


                                                    <div className="verification-result1">
                                                        <div>The owner of this credential has been verified</div>
                                                        <div className='row'>
                                                            <div className="verification-label">Issued to:</div>
                                                            {verificationData[3] && <div>{verificationData[3]}</div>}
                                                        </div>

                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[3] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Checking Certificate Status...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className='datess row'>
                                                    <div className="verification-itemm">
                                                        <div className="verification-result">
                                                            {/* <div className="success">&#10004;</div> */}
                                                            <div></div>
                                                        </div>
                                                        <div>
                                                            <div className="verification-label">Issued on:</div>

                                                            <div className="verification-result1">
                                                                {verificationData[0] && <div>{verificationData[0]}</div>}
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="verification-itemm">
                                                        <div className="verification-result">
                                                            {/* <div className="success">&#10004;</div> */}
                                                            <div></div>
                                                        </div>
                                                        <div>
                                                            <div className="verification-label">Expires on:</div>

                                                            <div className="verification-result1">
                                                                {verificationData[1] && <div>{verificationData[1]}</div>}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[4] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Verifying Credential Data...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success1"><img src={check}></img></div>
                                                    </div>
                                                    <div className="verification-label"></div>

                                                    <div className="verification-result1">
                                                        <div className='verified'>This Audit Certificate is VERIFIED</div>

                                                        <div className='verified1'>This digital credential was securely issued via EarthID and its information is valid.</div>


                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <footer className="footerr">
                        <div className="footerr-text">
                            © 2023 EarthID, Inc. All Rights Reserved.
                        </div>
                    </footer>
                </div>
            ) : (
                Swal.fire({
                    title: "No Certificate Found",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "Go Back",
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Redirect to the home page
                        window.location.href = "/uploadDoc";
                    }
                })
            )}
        </>

    );
};

export default Certificate;
