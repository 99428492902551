import React, { Component } from "react";
import openSocket from "socket.io-client";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import Home from "./components/Home/home";
import Certificate from "./components/Certificate/Certificate";
import CreateCred from "./generateCred";
import EmpCertificate from "./components/EmpCred/certificate";
import DocumentMetadata from "./components/UploadDoc/UploadPdf";
import Admin from "./components/Admin/Admin";
import ServiceNow from "./components/certserviceNow/serviceNow";
import Subscribe from "./components/Subscribe/subs";
import IDCertificate from "./components/IDCertificate/IDCertificate";
import GradCertificate from "./components/GradCert/GradCert";
import NewUser from "./components/UploadDoc/User";
import AllCreds from "./components/AllCreds/allCreds";
import AllDocs from "./components/AllDocs/allDocs";
import AllDocsMain from "./components/AllDocsMain/allDocsMain";

// export const isTestnet = true;
// const socketURL =
//   isTestnet === true
//     ? "https://socket-stagev2.myearth.id"
//     : "https://socketv2.myearth.id";
// const socket = openSocket(socketURL, {
//   transports: ["websocket", "polling", "flashsocket"],
// });
// let socketId;
// socket.on("connect", () => {
//   socketId = socket.id;
//   console.log(`I'm connected with the back-end`);
// });



class App extends Component {


  render() {
    return (
      <div className="App">


          <div className="content">
            <Router>
              <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/uploadDoc" component={DocumentMetadata} />
              <Route path="/admin/" component={Admin} />
              <Route path="/user" component={NewUser} />
              <Route path="/:userName/vcs/audit/:vcID" component={Certificate} />
              <Route path="/:userName/vcs/audit" component={AllCreds} />
              <Route path="/:userName/vcs" component={AllDocs} />
              <Route path="/:userName" component={AllDocsMain} />
              <Route path="/certificate/student-ID/:userName" component={IDCertificate} />
              <Route path="/certificate/degree/:userName" component={GradCertificate} />
              <Route path="/certificate/ServiceNow/" component={ServiceNow} />
              <Route path="/transactions" component={Subscribe} />
              </Switch>
            </Router>
          </div>
      </div>
    );
  }
}

export default App;
