import React, { useEffect, useState } from 'react';
import './allDocsMain.scss';
import { useParams } from "react-router-dom";
import logo from '../../img/EarthID Logo - Primary - BLACK.png';
import studentPhoto from '../../img/studentIcon.png';

const AllDocsMain = () => {
    const [responseData, setResponseData] = useState([]);

    const { userName } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const prefix = "AuditCertificate";
                const userNamee = `${prefix}${userName}`;
                console.log('Username', userNamee);
                const cidsResponse = await fetch(`https://auditdb.myearth.id/api/vcnew/company/${userNamee}/CertificateVc`);
                const cidJson = await cidsResponse.json();
                console.log('CidsResponse', cidJson);

                if (cidJson && cidJson.response && cidJson.response.length > 0) {
                    setResponseData(cidJson.response);
                } else {
                    console.log('Certificate not generated for', prefix);
                }
            } catch (error) {
                console.log('Error:', error);
            }
        };

        fetchData();
    }, [userName]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric' }).format(date);
    };

    return (
        <>
            <div className="page-container">
                <nav className="navbar-cert">
                    <div className="logoAll">
                        <img src={logo} alt="EarthID Logo" />
                    </div>
                </nav>
                <div className='userNameSec'>
                    <div className='userNameSec1 container'>
                        <div className='userImg'>
                            <img className='userImg1' src={studentPhoto} alt="User" />
                        </div>
                        <div>
                            <p className='userNameall'>{userName}</p>
                            <p className='credText'>Issued VCs and Submitted Documents</p>
                        </div>
                    </div>
                </div>
                <div className='cardSecAll'>
                    <div className='cardSecAll1 row'>
                        {responseData && responseData.length > 0 && (
                            <div className="col-md-4">
                                <a href={`/${userName}/vcs/`}>
                                    <div className="blog-cardmini">
                                        <div className='blogImg1'>
                                            {/* You can use different images based on vc.type if needed */}
                                            {/* <img src={vc.type === "Training" ? kpTraining : kpProfMem} alt="VC Image" className="blog-card__image" /> */}
                                        </div>
                                        <div className="blog-card__content">
                                            <h2 className="blog-card__title">Verifiable Credentials</h2>
                                            <p className="blog-card__date">Issued VCs</p>
                                            <p className="blog-card__issued-by">Issued by: EarthID</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            
                        )}
                        <div className="col-md-4">
                               
                                    <div className="blog-cardmini">
                                        <div className='blogImg1'>
                                            {/* You can use different images based on vc.type if needed */}
                                            {/* <img src={vc.type === "Training" ? kpTraining : kpProfMem} alt="VC Image" className="blog-card__image" /> */}
                                        </div>
                                        <div className="blog-card__content">
                                            <h2 className="blog-card__title">Documents</h2>
                                            <p className="blog-card__date">Submitted Documents</p>
                                            <p className="blog-card__issued-by">Issued by: EarthID</p>
                                        </div>
                                    </div>
                                
                            </div>
                    </div>
                </div>
            </div>
            <footer className="footerrall">
                <div className="footerr-text">
                    Powered by EarthID
                </div>
            </footer>
        </>
    );
};

export default AllDocsMain;
