import React, { useEffect, useState } from 'react';
import './allCreds.scss';
import { useParams } from "react-router-dom";
import logo from '../../img/EarthID Logo - Primary - BLACK.png';
import studentPhoto from '../../img/studentIcon.png';
import favicon from '../../img/EarthID FAVICON - PRIMARY.png'
import QRCode from 'qrcode.react';

const moment = require('moment');

const AllCreds = () => {
    const [responseData, setResponseData] = useState([]);

    const { userName } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const prefix = "AuditCertificate";
                const userNamee = `${prefix}${userName}`;
                console.log('Username', userNamee);
                const cidsResponse = await fetch(`https://auditdb.myearth.id/api/vcnew/company/${userNamee}/CertificateVc`);
                const cidJson = await cidsResponse.json();
                console.log('CidsResponse', cidJson);

                if (cidJson && cidJson.response && cidJson.response.length > 0) {
                    setResponseData(cidJson.response);
                } else {
                    console.log('Certificate not generated for', prefix);
                }
            } catch (error) {
                console.log('Error:', error);
            }
        };

        fetchData();
    }, [userName]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric' }).format(date);
    };

    return (
        <>
            <div className="page-container">
                <nav className="navbar-cert">
                    <div className="logoAll">
                        <img src={logo} alt="EarthID Logo" />
                    </div>
                </nav>
                <div className='userNameSec'>
                    <div className='userNameSec1 container'>
                        <div className='userImg'>
                            <img className='userImg1' src={studentPhoto} alt="User" />
                        </div>
                        <div>
                            <p className='userNameall'>{userName}</p>
                            <p className='credText'>{responseData.length} Certificates | 1 Issuer</p>
                        </div>
                    </div>
                </div>
                <div className='cardSecAll'>
                    <div className='cardSecAll1 row'>
                        {responseData && responseData.length > 0 && responseData.map((vc, index) => (
                            <div key={index} className="col-md-4">
                                <a href={`/${userName}/vcs/audit/${vc._id}`}>
                                    <div className="blog-cardmini">
                                        <div className='blogImg1'>
                                            {/* You can use different images based on vc.type if needed */}
                                            {/* <img src={vc.type === "Training" ? kpTraining : kpProfMem} alt="VC Image" className="blog-card__image" /> */}
                                            <div className="certificatedmini">
                                        <div className="bodbox">

                                            <div className="header">
                                                <div className="favico">
                                                    <img src={favicon}></img>
                                                </div>
                                                <div className="title">Audit Certificate</div>
                                            </div>
                                            <div className="title1">Year 2023-2024</div>
                                            <div className="title1">This certificate verifies that</div>

                                            <div className="body">
                                                <div className="name">{userName}</div>
                                                <div className="title1">has succesfully gone through the audit and <br></br>has completed all requirements for the necessary industry standards.<br></br>This certificate has been <br></br>
                                                issued on: {moment(vc.issuanceDate).format('MMMM Do YYYY, h:mm:ss A')}.
                                                </div>
                                            </div>
                                            <div>
                                                {/* Add the QRCode component */}
                                                {/* <div className="qrcode-container">
                                                    <QRCode className='qrCode' value={`https://${userName}/documents/audit/${vc._id}`} />
                                                </div> */}
                                                <div className='signature'>EarthID</div>
                                                {/* <div className='signature1'>KPMG</div> */}
                                            </div>


                                        </div>
                                    </div>
                                        </div>
                                        <div className="blog-card__content">
                                            <h2 className="blog-card__title">Audit Certificate</h2>
                                            <p className="blog-card__date">{formatDate(vc.createdAt)}</p>
                                            <p className="blog-card__issued-by">Issued by: EarthID</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <footer className="footerrall">
                <div className="footerr-text">
                    Powered by EarthID
                </div>
            </footer>
        </>
    );
};

export default AllCreds;
