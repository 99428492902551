import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import './admin.css';

const UserCertTable = () => {
  const [userCerts, setUserCerts] = useState([]);

  useEffect(() => {
    // Fetch the API data when the component mounts
    fetch('https://auditdb.myearth.id/api/certificate/getAllUserCerts')
      .then(response => response.json())
      .then(data => {
        // Filter the array to include only distinct userName values
        const distinctUserCerts = Array.from(new Set(data.map(item => item.userName)))
          .map(userName => data.find(item => item.userName === userName));
        setUserCerts(distinctUserCerts);
      })
      .catch(error => console.error('Error fetching user certs:', error));
  }, []);


  const handleViewCertificate = (userName) => {
    // Perform any necessary actions before redirecting

    // Redirect to the desired component
    window.open(`/${userName}/vcs/audit/`, '_blank');
};

  return (
    <>
       <div className='user-tablee'>
      <table className='user-table'>
        <thead>
          <tr>
          <th>Sr No.</th>
            <th>Customer Name</th>
            <th>Issued Certificate</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {userCerts.map((userCert, index) => (
            <tr key={index}>
                 <td>{index + 1}</td>
              <td>{userCert.userName}</td>
              <td>{userCert.certName}</td>
              <td>
              <FontAwesomeIcon
                    onClick={() => handleViewCertificate(userCert.userName)}
                    className="eyeIcon"
                    icon={faEye}
                  />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
 
  );
}

export default UserCertTable;
